import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'

const Layout = () => import(/* webpackChunkName: 'Layout' */ '../views/layout/Layout')  // 公共头部、左侧菜单、主体内容框架
const Login = () => import(/* webpackChunkName: 'Login' */ '@/views/operatePage/login') // 登录界面
const userInfor = () => import(/* webpackChunkName: 'userInfor' */ '@/views/operatePage/userInformation') // 个人信息
const Index = () => import(/* webpackChunkName: 'Index' */ '@/views/operatePage/yypt-index') // 首页

Vue.use(VueRouter)

const originalPush = VueRouter.prototype.push
//修改原型对象中的push方法
//解决vue路由重复导航错误
//获取原型对象上的push函数
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

export const constantRouterMap = [{
  path: '/',
  component: Layout,
  redirect: '/index',
  name: '首页',
  hidden: true,
  children: [{ path: 'index', component: Index }]
}, {
  path: '/login',
  name: 'login',
  meta: {
    title: '致程标准化后台'
  },
  component: Login
}, {
  path: '/general',
  component: Layout,
  name: '通用页面',
  children: [{ path: 'userInfor', component: userInfor, name: '个人信息' }]
}]

// 用户管理
const examine = () => import(/* webpackChunkName: 'examine' */ '@/views/userManage/examine') // 待审核
const editExamineHospital = () => import(/* webpackChunkName: 'editExamineHospital' */ '@/views/userManage/editExamineHospital') // 修改医陪审核
const editExamineAtHome = () => import(/* webpackChunkName: 'editExamineAtHome' */ '@/views/userManage/editExamineAtHome') // 修改居家审核
const Medical = () => import(/* webpackChunkName: 'medicalNursing' */ '@/views/userManage/medicalNursing') // 在院护工
const newMedical = () => import(/* webpackChunkName: 'newMedicalNursing' */ '@/views/userManage/newMedicalNursing') // 医陪护工详情
const transportcare = () => import(/* webpackChunkName: 'transportcare' */ '@/views/userManage/transportCare') // 转运护工
const newTransportCare = () => import(/* webpackChunkName: 'newTransportCare' */ '@/views/userManage/newTransportCare') // 转运护工详情
const homecare = () => import(/* webpackChunkName: 'homecare' */ '@/views/userManage/homecareNursing') // 居家护工
const newHomecare = () => import(/* webpackChunkName: 'newHomecareNursing' */ '@/views/userManage/newHomecareNursing') // 居家护工详情
const medmonitor = () => import(/* webpackChunkName: 'medmonitor' */ '@/views/userManage/medmonitor') // 医院监管
const hosuser = () => import(/* webpackChunkName: 'hosuser' */ '@/views/userManage/hosuser') // 医陪用户
const hosuserDetails = () => import(/* webpackChunkName: 'hosuserDetails' */ '@/views/userManage/hosuserDetails') // 医陪用户详情
const homuser = () => import(/* webpackChunkName: 'homuser' */ '@/views/userManage/homuser') // 居家用户
const homuserDetails = () => import(/* webpackChunkName: 'homuserDetails' */ '@/views/userManage/homuserDetails') // 居家用户详情
const brokerage = () => import(/* webpackChunkName: 'brokerage' */ '@/views/userManage/brokerage') // 经纪用户
const brokerageDetails = () => import(/* webpackChunkName: 'brokerageDetails' */ '@/views/userManage/brokerageDetails') // 经纪用户详情
const custrmanagement = () => import(/* webpackChunkName: 'custrmanagement' */ '@/views/userManage/custrManagement') // 客户管理
const custrManageDetails = () => import(/* webpackChunkName: 'custrManageDetails' */ '@/views/userManage/custrManageDetails') // 客户管理详情
const RoutUserManage = {
  path: '/usermanage',
  component: Layout,
  name: '用户管理',
  // icon
  code: 'USE',
  children: [
    { path: 'examine', component: examine, name: '待审核', code: 'USE_EXA', meta: { code: 'USE_EXA' } },
    { path: 'editExamineHospital', component: editExamineHospital, name: '修改医陪审核', hidden: true },
    { path: 'editExamineAtHome', component: editExamineAtHome, name: '修改居家审核', hidden: true },
    { path: 'Medical', component: Medical, name: '在院护工', code: 'USE_MUR', meta: { code: 'USE_MUR' } },
    { path: 'newMedical', component: newMedical, name: '医陪护工详情', hidden: true },
    { path: 'transportcare', component: transportcare, name: '转运护工', code: 'USE_TRA', meta: { code: 'USE_TRA' } },
    { path: 'newTransportCare', component: newTransportCare, name: '转运护工详情', hidden: true },
    { path: 'homecare', component: homecare, name: '居家护工', code: 'USE_HOM', meta: { code: 'USE_HOM' } },
    { path: 'newHomecare', component: newHomecare, name: '居家护工详情', hidden: true },
    { path: 'medmonitor', component: medmonitor, name: '医院监管', code: 'USE_MED', meta: { code: 'USE_MED' } },
    { path: 'hosuser', component: hosuser, name: '医陪用户', code: 'USE_HOS', meta: { code: 'USE_HOS' } },
    { path: 'hosuserDetails', component: hosuserDetails, name: '医陪用户详情', hidden: true },
    { path: 'homuser', component: homuser, name: '居家用户', code: 'USE_HOU', meta: { code: 'USE_HOU' } },
    { path: 'homuserDetails', component: homuserDetails, name: '居家用户详情', hidden: true },
    { path: 'brokerage', component: brokerage, name: '经纪用户', code: 'USE_BRO', meta: { code: 'USE_BRO' } },
    { path: 'brokerageDetails', component: brokerageDetails, name: '经纪用户详情', hidden: true },
    { path: 'custrmanagement', component: custrmanagement, name: '客户管理', code: 'USE_CRM', meta: { code: 'USE_CRM' } },
    { path: 'custrManageDetails', component: custrManageDetails, name: '客户管理详情', hidden: true },
  ]
}

// 订单管理
const hospitalreser = () => import(/* webpackChunkName: 'hospitalreser' */ '@/views/orderManage/hospitalReservation') // 医护预约
const newHospitalReser = () => import(/* webpackChunkName: 'newHospitalReser' */ '@/views/orderManage/newHospitalReser') // 医护预约详情
const homereser = () => import(/* webpackChunkName: 'homereser' */ '@/views/orderManage/homeReservation') // 居家预约
const newHomeReser = () => import(/* webpackChunkName: 'newHomeReser' */ '@/views/orderManage/newHomeReser') // 居家预约详情
const hospitorder = () => import(/* webpackChunkName: 'hospitorder' */ '@/views/orderManage/hospitOrder') // 医护订单
const newHospitOrder = () => import(/* webpackChunkName: 'newHospitOrder' */ '@/views/orderManage/newHospitOrder') // 医护详情 医护订单处理
const hospitOrderDetails = () => import(/* webpackChunkName: 'hospitOrderDetails' */ '@/views/orderManage/hospitOrderDetails') // 医护订单详情
const newServiceRecord = () => import(/* webpackChunkName: 'newServiceRecord' */ '@/views/orderManage/newServiceRecord.vue') // 服务记录
const homeorder = () => import(/* webpackChunkName: 'homeorder' */ '@/views/orderManage/homeOrder') // 居家订单
const newHomeOrder = () => import(/* webpackChunkName: 'newHomeOrder' */ '@/views/orderManage/newHomeOrder') // 居家详情
const homeOrderDetails = () => import(/* webpackChunkName: 'homeOrderDetails' */ '@/views/orderManage/homeOrderDetails') // 居家订单详情
const settleAccounts = () => import(/* webpackChunkName: 'settleAccounts' */ '@/views/orderManage/settleAccounts') // 订单结账 医护
const settleAccountsHome = () => import(/* webpackChunkName: 'settleAccountsHome' */ '@/views/orderManage/settleAccountsHome.vue') // 订单结账 居家
const historyorder = () => import(/* webpackChunkName: 'historyorder' */ '@/views/orderManage/historyOrder') // 历史订单
const transactionflow = () => import(/* webpackChunkName: 'transactionflow' */ '@/views/orderManage/transactionFlow') // 医护流水
const hometransaction = () => import(/* webpackChunkName: 'transactionflow' */ '@/views/orderManage/hometransaction') // 居家流水
const accappointment = () => import(/* webpackChunkName: 'accappointment' */ '@/views/orderManage/accAppointment') // 陪诊预约
const newAccAppointment = () => import(/* webpackChunkName: 'newAccAppointment' */ '@/views/orderManage/newAccAppointment') // 陪诊预约详情
const acoorder = () => import(/* webpackChunkName: 'acoorder' */ '@/views/orderManage/acoOrder') // 陪诊订单
const newAcoOrder = () => import(/* webpackChunkName: 'newAcoOrder' */ '@/views/orderManage/newAcoOrder') // 陪诊详情 陪诊订单处理
const acoOrderDetails = () => import(/* webpackChunkName: 'acoOrderDetails' */ '@/views/orderManage/acoOrderDetails') // 陪诊订单详情
const settleAccountsDiagnosis = () => import(/* webpackChunkName: 'settleAccountsDiagnosis' */ '@/views/orderManage/settleAccountsDiagnosis') // 订单结账 陪诊
const runappointment = () => import(/* webpackChunkName: 'runappointment' */ '@/views/orderManage/runAppointment') // 跑腿预约
const newRunAppointment = () => import(/* webpackChunkName: 'newRunAppointment' */ '@/views/orderManage/newRunAppointment') // 跑腿预约详情
const ruoorder = () => import(/* webpackChunkName: 'ruoorder' */ '@/views/orderManage/ruoOrder') // 跑腿订单
const newRuoOrder = () => import(/* webpackChunkName: 'newRuoOrder' */ '@/views/orderManage/newRuoOrder') // 跑腿详情 跑腿订单处理
const ruoOrderDetails = () => import(/* webpackChunkName: 'ruoOrderDetails' */ '@/views/orderManage/ruoOrderDetails') // 跑腿订单详情
const settleAccountsErrand = () => import(/* webpackChunkName: 'settleAccountsErrand' */ '@/views/orderManage/settleAccountsErrand') // 订单结账 跑腿
const accomedrecords = () => import(/* webpackChunkName: 'accomedrecords' */ '@/views/orderManage/accoMedRecords') // 陪诊流水
const runningerrands = () => import(/* webpackChunkName: 'runningerrands' */ '@/views/orderManage/runningErrands') // 跑腿流水
const RoutOrderManage = {
  path: '/ordermanage',
  component: Layout,
  name: '订单管理',
  // icon
  code: 'ORD',
  children: [
    { path: 'hospitalreser', component: hospitalreser, name: '医护预约', code: 'ORD_MRE', meta: { code: 'ORD_MRE' } },
    { path: 'newHospitalReser', component: newHospitalReser, name: '医护预约详情', hidden: true },
    { path: 'homereser', component: homereser, name: '居家预约', code: 'ORD_HRE', meta: { code: 'ORD_HRE' } },
    { path: 'newHomeReser', component: newHomeReser, name: '居家预约详情', hidden: true },
    { path: 'hospitorder', component: hospitorder, name: '医护订单', code: 'ORD_MOR', meta: { code: 'ORD_MOR' } },
    { path: 'newHospitOrder', component: newHospitOrder, name: '医护详情', hidden: true },
    { path: 'hospitOrderDetails', component: hospitOrderDetails, name: '医护订单详情', hidden: true },
    { path: 'newServiceRecord', component: newServiceRecord, name: '服务记录', hidden: true },
    { path: 'homeorder', component: homeorder, name: '居家订单', code: 'ORD_HOR', meta: { code: 'ORD_HOR' } },
    { path: 'newHomeOrder', component: newHomeOrder, name: '居家详情', hidden: true },
    { path: 'homeOrderDetails', component: homeOrderDetails, name: '居家订单详情', hidden: true },
    { path: 'settleAccounts', component: settleAccounts, name: '医护订单结账', hidden: true },
    { path: 'settleAccountsHome', component: settleAccountsHome, name: '居家订单结账', hidden: true },
    { path: 'historyorder', component: historyorder, name: '历史订单', code: 'ORD_HIS', meta: { code: 'ORD_HIS' } },
    { path: 'transactionflow', component: transactionflow, name: '医护流水', code: 'ORD_TRA', meta: { code: 'ORD_TRA' } },
    { path: 'hometransaction', component: hometransaction, name: '居家流水', code: 'ORD_TRH', meta: { code: 'ORD_TRH' } },
    { path: 'accappointment', component: accappointment, name: '陪诊预约', code: 'ORD_ACC', meta: { code: 'ORD_ACC' } },
    { path: 'newAccAppointment', component: newAccAppointment, name: '陪诊预约详情', hidden: true },
    { path: 'acoorder', component: acoorder, name: '陪诊订单', code: 'ORD_ACO', meta: { code: 'ORD_ACO' } },
    { path: 'newAcoOrder', component: newAcoOrder, name: '陪诊详情', hidden: true },
    { path: 'acoOrderDetails', component: acoOrderDetails, name: '陪诊订单详情', hidden: true },
    { path: 'settleAccountsDiagnosis', component: settleAccountsDiagnosis, name: '陪诊订单结账', hidden: true },
    { path: 'runappointment', component: runappointment, name: '跑腿预约', code: 'ORD_RUN', meta: { code: 'ORD_RUN' } },
    { path: 'newRunAppointment', component: newRunAppointment, name: '跑腿预约详情', hidden: true },
    { path: 'ruoorder', component: ruoorder, name: '跑腿订单', code: 'ORD_RUO', meta: { code: 'ORD_RUO' } },
    { path: 'newRuoOrder', component: newRuoOrder, name: '跑腿详情', hidden: true },
    { path: 'ruoOrderDetails', component: ruoOrderDetails, name: '跑腿订单详情', hidden: true },
    { path: 'settleAccountsErrand', component: settleAccountsErrand, name: '跑腿订单结账', hidden: true },
    { path: 'accomedrecords', component: accomedrecords, name: '陪诊流水', code: 'ORD_AMR', meta: { code: 'ORD_AMR' } },
    { path: 'runningerrands', component: runningerrands, name: '跑腿流水', code: 'ORD_RUE', meta: { code: 'ORD_RUE' } },
  ],
}

// 班组管理
const teammanagement = () => import(/* webpackChunkName: 'teammanagement' */ '@/views/teamManage/teamManagement') // 班组管理
const newTeamManage = () => import(/* webpackChunkName: 'newTeamManage' */ '@/views/teamManage/newTeamManage') // 班组详情
const nextwork = () => import(/* webpackChunkName: 'nextworkManage' */ '@/views/teamManage/nextworkManage') // 交班管理
const newNextwork = () => import(/* webpackChunkName: 'newNextwork' */ '@/views/teamManage/newNextwork') // 交班详情
const askforleave = () => import(/* webpackChunkName: 'askforleave' */ '@/views/teamManage/askForLeave') // 请假管理
const checkwork = () => import(/* webpackChunkName: 'checkwork' */ '@/views/teamManage/checkwork') // 考勤签到
const attendance = () => import(/* webpackChunkName: 'attendance' */ '@/views/teamManage/attendance') // 考勤确认
const RoutTeamManage = {
  path: '/teammanage',
  component: Layout,
  name: '班组管理',
  // icon
  code: 'TEA',
  children: [
    { path: 'teammanagement', component: teammanagement, name: '班组管理', code: 'TEA_MAN', meta: { code: 'TEA_MAN' } },
    { path: 'newTeamManage', component: newTeamManage, name: '班组详情', hidden: true },
    { path: 'nextwork', component: nextwork, name: '交班管理', code: 'TEA_NEX', meta: { code: 'TEA_NEX' } },
    { path: 'newNextwork', component: newNextwork, name: '交班详情', hidden: true },
    { path: 'askforleave', component: askforleave, name: '请假管理', code: 'TEA_LEA', meta: { code: 'TEA_LEA' } },
    { path: 'checkwork', component: checkwork, name: '考勤签到', code: 'TEA_CHE', meta: { code: 'TEA_CHE' } },
    { path: 'attendance', component: attendance, name: '考勤确认', code: 'TEA_CON', meta: { code: 'TEA_CON' } },
  ]
}

// 运营管理  
const servicePreview = () => import(/* webpackChunkName: 'servicePreview' */ '@/views/operationManage/servicePreview') // 服务概览
const bedstatediagram = () => import(/* webpackChunkName: 'bedStateDiagram' */ '@/views/operationManage/bedStateDiagram') // 床态概览
const specialtreatment = () => import(/* webpackChunkName: 'specialtreatment' */ '@/views/operationManage/specialTreatMent') // 医护处理
const specialhome = () => import(/* webpackChunkName: 'specialhome' */ '@/views/operationManage/specialHome') // 居家处理
const careworker = () => import(/* webpackChunkName: 'careWorker' */ '@/views/operationManage/careWorker') // 医护列表/在院列表
const homecarelist = () => import(/* webpackChunkName: 'homecarelist' */ '@/views/operationManage/homecarelist') // 居家列表
const invoicing = () => import(/* webpackChunkName: 'invoicing' */ '@/views/operationManage/invoicing') // 医护开票
const homeInvoic = () => import(/* webpackChunkName: 'homeInvoic' */ '@/views/operationManage/homeInvoic') // 居家开票
const servinsurance = () => import(/* webpackChunkName: 'servinsurance' */ '@/views/operationManage/servInsurance') // 服务保险
const acctreatment = () => import(/* webpackChunkName: 'acctreatment' */ '@/views/operationManage/accTreatment') // 陪诊处理
const errahandl = () => import(/* webpackChunkName: 'errahandl' */ '@/views/operationManage/errandHandle') // 跑腿处理
const acconinvoice = () => import(/* webpackChunkName: 'acconinvoice' */ '@/views/operationManage/acconInvoice') // 陪诊开票
const runerinvoices = () => import(/* webpackChunkName: 'runerinvoices' */ '@/views/operationManage/runerInvoices') // 跑腿开票
const RoutOperationManage = {
  path: '/operationmanage',
  component: Layout,
  name: '运营管理',
  // icon
  code: 'OPE',
  children: [
    { path: 'servicePreview', component: servicePreview, name: '服务概览', code: 'OPE_PRE', meta: { code: 'OPE_PRE' } },
    { path: 'bedstatediagram', component: bedstatediagram, name: '床态概览', code: 'OPE_BED', meta: { code: 'OPE_BED' } },
    { path: 'specialtreatment', component: specialtreatment, name: '医护处理', code: 'OPE_SPE', meta: { code: 'OPE_SPE' } },
    { path: 'specialhome', component: specialhome, name: '居家处理', code: 'OPE_SPH', meta: { code: 'OPE_SPH' } },
    { path: 'careworker', component: careworker, name: '在院列表', code: 'OPE_CAR', meta: { code: 'OPE_CAR' } },
    { path: 'homecarelist', component: homecarelist, name: '居家列表', code: 'OPE_HAR', meta: { code: 'OPE_HAR' } },
    { path: 'invoicing', component: invoicing, name: '医护开票', code: 'OPE_INV', meta: { code: 'OPE_INV' } },
    { path: 'homeInvoic', component: homeInvoic, name: '居家开票', code: 'OPE_HIN', meta: { code: 'OPE_HIN' } },
    { path: 'servinsurance', component: servinsurance, name: '服务保险', code: 'OPE_SER', meta: { code: 'OPE_SER' } },
    { path: 'acctreatment', component: acctreatment, name: '陪诊处理', code: 'OPE_ACT', meta: { code: 'OPE_ACT' } },
    { path: 'errahandl', component: errahandl, name: '跑腿处理', code: 'OPE_ERH', meta: { code: 'OPE_ERH' } },
    { path: 'acconinvoice', component: acconinvoice, name: '陪诊开票', code: 'OPE_ACI', meta: { code: 'OPE_ACI' } },
    { path: 'runerinvoices', component: runerinvoices, name: '跑腿开票', code: 'OPE_RUI', meta: { code: 'OPE_RUI' } },
  ]
}

// 质量管理  
const patrolevaluate = () => import(/* webpackChunkName: 'patrolEvaluate' */ '@/views/quelityManage/patrolEvaluate') // 管理巡查
const newOperateEvaluate = () => import(/* webpackChunkName: 'newOperateEvaluate' */ '@/views/quelityManage/newOperateEvaluate') // 管理巡查处理
const operateEvaluateDetails = () => import(/* webpackChunkName: 'operateEvaluateDetails' */ '@/views/quelityManage/operateEvaluateDetails') // 管理巡查详情
const hospitalevaluate = () => import(/* webpackChunkName: 'hospitalevaluate' */ '@/views/quelityManage/hospitalEvaluate') // 医院评价
const hospitalEvaluateDetails = () => import(/* webpackChunkName: 'hospitalEvaluateDetails' */ '@/views/quelityManage/hospitalEvaluateDetails') // 医院评价详情
const customevaluate = () => import(/* webpackChunkName: 'customEvaluate' */ '@/views/quelityManage/customEvaluate') // 医客评价
const customEvaluateDetails = () => import(/* webpackChunkName: 'customEvaluateDetails' */ '@/views/quelityManage/customEvaluateDetails') // 医客评论详情
const homeevaluate = () => import(/* webpackChunkName: 'homeevaluate' */ '@/views/quelityManage/homeEvaluate') // 居客评价
const nursetraining = () => import(/* webpackChunkName: 'nursetraining' */ '@/views/quelityManage/nurseTraining') // 护工培训
const employeetraining = () => import(/* webpackChunkName: 'employeetraining' */ '@/views/quelityManage/employeeTraining') // 员工培训
const nurtrarecords = () => import(/* webpackChunkName: 'nurtrarecords' */ '@/views/quelityManage/nurtraRecords') // 护培记录
const emptrarecords = () => import(/* webpackChunkName: 'emptrarecords' */ '@/views/quelityManage/emptraRecords') // 员培记录
const nursepatrol = () => import(/* webpackChunkName: 'nursepatrol' */ '@/views/quelityManage/nursePatrol') // 护工巡查
const newNursePatrol = () => import(/* webpackChunkName: 'newNursePatrol' */ '@/views/quelityManage/newNursePatrol') // 护工巡查处理
const accompanying = () => import(/* webpackChunkName: 'accompanying' */ '@/views/quelityManage/accompanying') // 陪诊评价
const runerrands = () => import(/* webpackChunkName: 'runerrands' */ '@/views/quelityManage/runErrands') // 跑腿评价
const RoutQuelityManage = {
  path: '/quelitymanage',
  component: Layout,
  name: '质量管理',
  // icon
  code: 'QUE',
  children: [
    { path: 'patrolevaluate', component: patrolevaluate, name: '管理巡查', code: 'QUE_PAT', meta: { code: 'QUE_PAT' } },
    { path: 'newOperateEvaluate', component: newOperateEvaluate, name: '管巡处理', hidden: true },
    { path: 'operateEvaluateDetails', component: operateEvaluateDetails, name: '管巡详情', hidden: true },
    { path: 'hospitalevaluate', component: hospitalevaluate, name: '医院评价', code: 'QUE_HAT', meta: { code: 'QUE_HAT' } },
    { path: 'hospitalEvaluateDetails', component: hospitalEvaluateDetails, name: '医评详情', hidden: true },
    { path: 'customevaluate', component: customevaluate, name: '医客评价', code: 'QUE_CUS', meta: { code: 'QUE_CUS' } },
    { path: 'customEvaluateDetails', component: customEvaluateDetails, name: '客评详情', hidden: true },
    { path: 'homeevaluate', component: homeevaluate, name: '居客评价', code: 'QUE_HUS', meta: { code: 'QUE_HUS' } },
    { path: 'nursetraining', component: nursetraining, name: '护工培训', code: 'QUE_NTR', meta: { code: 'QUE_NTR' } },
    { path: 'employeetraining', component: employeetraining, name: '员工培训', code: 'QUE_EMT', meta: { code: 'QUE_EMT' } },
    { path: 'nurtrarecords', component: nurtrarecords, name: '护培记录', code: 'QUE_NRE', meta: { code: 'QUE_NRE' } },
    { path: 'emptrarecords', component: emptrarecords, name: '员培记录', code: 'QUE_ERE', meta: { code: 'QUE_ERE' } },
    { path: 'nursepatrol', component: nursepatrol, name: '护工巡查', code: 'QUE_NUP', meta: { code: 'QUE_NUP' } },
    { path: 'newNursePatrol', component: newNursePatrol, name: '护巡处理', hidden: true },
    { path: 'accompanying', component: accompanying, name: '陪诊评价', code: 'QUE_ACC', meta: { code: 'QUE_ACC' } },
    { path: 'runerrands', component: runerrands, name: '跑腿评价', code: 'QUE_RUN', meta: { code: 'QUE_RUN' } },
  ]
}

// 财务管理
const teamsettlement = () => import(/* webpackChunkName: 'teamSettlement' */ '@/views/financialManage/teamSettlement') // 班组清算
const teamSettleDetails = () => import(/* webpackChunkName: 'teamSettleDetails' */ '@/views/financialManage/teamSettleDetails') // 班组结算详情
const dedicatedcom = () => import(/* webpackChunkName: 'dedicatedcom' */ '@/views/financialManage/dedicatedcom') // 专陪清算
const homesettlement = () => import(/* webpackChunkName: 'homeSettlement' */ '@/views/financialManage/homeSettlement') // 居家清算
const brokersettlement = () => import(/* webpackChunkName: 'brokerSettlement' */ '@/views/financialManage/brokerSettlement') // 经纪清算
const distribution = () => import(/* webpackChunkName: 'distribution' */ '@/views/financialManage/distribution') // 专陪记录
const homedistribution = () => import(/* webpackChunkName: 'homedistribution' */ '@/views/financialManage/homedistribution') // 居家记录
const brokerrecord = () => import(/* webpackChunkName: 'brokerRecord' */ '@/views/financialManage/brokerRecord') // 经纪记录
const teamRecord = () => import(/* webpackChunkName: 'teamRecord' */ '@/views/financialManage/teamRecord') // 班组记录
const accomdiagnosis = () => import(/* webpackChunkName: 'accomdiagnosis' */ '@/views/financialManage/accomDiagnosis') // 陪诊清算
const runeraccounts = () => import(/* webpackChunkName: 'runeraccounts' */ '@/views/financialManage/runerAccounts') // 跑腿清算
const accdiarecords = () => import(/* webpackChunkName: 'accdiarecords' */ '@/views/financialManage/accDiaRecords') // 陪诊记录
const runerrarecords = () => import(/* webpackChunkName: 'runerrarecords' */ '@/views/financialManage/runErraRecords') // 跑腿记录
const RoutFinancialManage = {
  path: '/finmanag',
  component: Layout,
  name: '财务管理',
  // icon
  code: 'FIN',
  children: [
    { path: 'teamsettlement', component: teamsettlement, name: '班组清算', code: 'FIN_TEA', meta: { code: 'FIN_TEA' } },
    { path: 'teamSettleDetails', component: teamSettleDetails, name: '班组清算详情', hidden: true },
    { path: 'dedicatedcom', component: dedicatedcom, name: '专陪清算', code: 'FIN_DED', meta: { code: 'FIN_DED' } },
    { path: 'homesettlement', component: homesettlement, name: '居家清算', code: 'FIN_HOM', meta: { code: 'FIN_HOM' } },
    { path: 'brokersettlement', component: brokersettlement, name: '经纪清算', code: 'FIN_BRO', meta: { code: 'FIN_BRO' } },
    { path: 'distribution', component: distribution, name: '专陪记录', code: 'FIN_DIS', meta: { code: 'FIN_DIS' } },
    { path: 'homedistribution', component: homedistribution, name: '居家记录', code: 'FIN_DIH', meta: { code: 'FIN_DIH' } },
    { path: 'brokerrecord', component: brokerrecord, name: '经纪记录', code: 'FIN_BRE', meta: { code: 'FIN_BRE' } },
    { path: 'teamRecord', component: teamRecord, name: '班组记录', code: 'FIN_TEM', meta: { code: 'FIN_TEM' } },
    { path: 'accomdiagnosis', component: accomdiagnosis, name: '陪诊清算', code: 'FIN_ADS', meta: { code: 'FIN_ADS' } },
    { path: 'runeraccounts', component: runeraccounts, name: '跑腿清算', code: 'FIN_RSA', meta: { code: 'FIN_RSA' } },
    { path: 'accdiarecords', component: accdiarecords, name: '陪诊记录', code: 'FIN_ADR', meta: { code: 'FIN_ADR' } },
    { path: 'runerrarecords', component: runerrarecords, name: '跑腿记录', code: 'FIN_RER', meta: { code: 'FIN_RER' } },
  ]
}

// 医护报表统计
const finsummary = () => import(/* webpackChunkName: 'finSummary' */ '@/views/reportStatistics/finSummary') // 医护财务总表
const financedetailed = () => import(/* webpackChunkName: 'financeDetailed' */ '@/views/reportStatistics/financeDetailed') // 医护财务明细
// const wardstatistics = () => import(/* webpackChunkName: 'wardStatistics' */ '@/views/reportStatistics/wardStatistics') // 病区统计
const servicestatistics = () => import(/* webpackChunkName: 'serviceStatistics' */ '@/views/reportStatistics/serviceStatistics') // 服务统计
const carestatistics = () => import(/* webpackChunkName: 'careStatistics' */ '@/views/reportStatistics/careStatistics') // 专陪工时
const teamstatistics = () => import(/* webpackChunkName: 'teamStatistics' */ '@/views/reportStatistics/teamStatistics') // 班组工时统计
const projectstatistics = () => import(/* webpackChunkName: 'projectStatistics' */ '@/views/reportStatistics/projectStatistics') // 项目工时统计
const orderstatistics = () => import(/* webpackChunkName: 'orderStatistics' */ '@/views/reportStatistics/orderStatistics') // 订单统计
const registrationstatistics = () => import(/* webpackChunkName: 'registrationStatistics' */ '@/views/reportStatistics/registrationStatistics') // 挂单统计
const RoutReportStatistics = {
  path: '/reportstatistics',
  component: Layout,
  name: '医护报表',
  // icon
  code: 'REP',
  children: [
    { path: 'finsummary', component: finsummary, name: '医护财务总表', code: 'REP_SUM', meta: { code: 'REP_SUM' } },
    { path: 'financedetailed', component: financedetailed, name: '医护财务明细', code: 'REP_DET', meta: { code: 'REP_DET' } },
    // { path: 'wardstatistics', component: wardstatistics, name: '病区统计', code: 'REP_WAR', meta: { code: 'REP_WAR' } },
    { path: 'servicestatistics', component: servicestatistics, name: '医护服务统计', code: 'REP_SER', meta: { code: 'REP_SER' } },
    { path: 'carestatistics', component: carestatistics, name: '专陪工时', code: 'REP_CAR', meta: { code: 'REP_CAR' } },
    { path: 'teamstatistics', component: teamstatistics, name: '班组工时统计', code: 'REP_TEA', meta: { code: 'REP_TEA' } },
    { path: 'projectstatistics', component: projectstatistics, name: '项目工时统计', code: 'REP_PRO', meta: { code: 'REP_PRO' } },
    { path: 'orderstatistics', component: orderstatistics, name: '医护订单统计', code: 'REP_ORD', meta: { code: 'REP_ORD' } },
    { path: 'registrationstatistics', component: registrationstatistics, name: '挂单统计', code: 'REP_REG', meta: { code: 'REP_REG' } },
  ],
}

// 陪诊报表统计
const accfinacialtable = () => import(/* webpackChunkName: 'accfinacialtable' */ '@/views/accomReport/accFinacialTable') // 陪诊财务总表
const accfinancialpatients = () => import(/* webpackChunkName: 'accfinancialpatients' */ '@/views/accomReport/accFinancialPatients') // 陪诊财务明细
const accservicestatistices = () => import(/* webpackChunkName: 'accservicestatistices' */ '@/views/accomReport/accServiceStatistices') // 陪诊服务统计
const accorderstatistics = () => import(/* webpackChunkName: 'accorderstatistics' */ '@/views/accomReport/accOrderStatistics') // 陪诊订单统计
const RoutAccomReport = {
  path: '/accomReport',
  component: Layout,
  name: '陪诊报表',
  // icon
  code: 'ACR',
  children: [
    { path: 'accfinacialtable', component: accfinacialtable, name: '陪诊财务总表', code: 'ACR_SUM', meta: { code: 'ACR_SUM' } },
    { path: 'accfinancialpatients', component: accfinancialpatients, name: '陪诊财务明细', code: 'ACR_APF', meta: { code: 'ACR_APF' } },
    { path: 'accservicestatistices', component: accservicestatistices, name: '陪诊服务统计', code: 'ACR_SER', meta: { code: 'ACR_SER' } },
    { path: 'accorderstatistics', component: accorderstatistics, name: '陪诊订单统计', code: 'ACR_ORD', meta: { code: 'ACR_ORD' } },
  ],
}

// 跑腿报表统计
const erefinacialtable = () => import(/* webpackChunkName: 'erefinacialtable' */ '@/views/errandReport/ereFinacialTable') // 跑腿财务总表
const erefinancialpatients = () => import(/* webpackChunkName: 'erefinancialpatients' */ '@/views/errandReport/ereFinancialPatients') // 跑腿财务明细
const ereservicestatistices = () => import(/* webpackChunkName: 'ereservicestatistices' */ '@/views/errandReport/ereServiceStatistices') // 跑腿服务统计
const ereorderstatistics = () => import(/* webpackChunkName: 'ereorderstatistics' */ '@/views/errandReport/ereOrderStatistics') // 跑腿订单统计
const RoutErrandReport = {
  path: '/errandreport',
  component: Layout,
  name: '跑腿报表',
  // icon
  code: 'ERE',
  children: [
    { path: 'erefinacialtable', component: erefinacialtable, name: '跑腿财务总表', code: 'ERE_SUM', meta: { code: 'ERE_SUM' } },
    { path: 'erefinancialpatients', component: erefinancialpatients, name: '跑腿财务明细', code: 'ERE_APF', meta: { code: 'ERE_APF' } },
    { path: 'ereservicestatistices', component: ereservicestatistices, name: '跑腿服务统计', code: 'ERE_SER', meta: { code: 'ERE_SER' } },
    { path: 'ereorderstatistics', component: ereorderstatistics, name: '跑腿订单统计', code: 'ERE_ORD', meta: { code: 'ERE_ORD' } },
  ],
}

// 居家报表
const financehome = () => import(/* webpackChunkName: 'financehome' */ '@/views/reportHome/financehome') // 居家财务总表
const finhomedetailed = () => import(/* webpackChunkName: 'finhomedetailed' */ '@/views/reportHome/finhomedetailed') // 居家财务明细
const homeServicestatistics = () => import(/* webpackChunkName: 'homeServicestatistics' */ '@/views/reportHome/homeServicestatistics') // 居家服务统计
const carehome = () => import(/* webpackChunkName: 'carehome' */ '@/views/reportHome/carehome') // 居家工时
const orderhome = () => import(/* webpackChunkName: 'orderhome' */ '@/views/reportHome/orderhome') // 居家订单统计
const RoutReporthome = {
  path: '/reporthome',
  component: Layout,
  name: '居家报表',
  // icon
  code: 'RHP',
  children: [
    { path: 'financehome', component: financehome, name: '居家财务总表', code: 'RHP_SUM', meta: { code: 'RHP_SUM' } },
    { path: 'finhomedetailed', component: finhomedetailed, name: '居家财务明细', code: 'RHP_DET', meta: { code: 'RHP_DET' } },
    { path: 'homeServicestatistics', component: homeServicestatistics, name: '居家服务统计', code: 'RHP_SER', meta: { code: 'RHP_SER' } },
    { path: 'carehome', component: carehome, name: '居家工时', code: 'RHP_CAR', meta: { code: 'RHP_CAR' } },
    { path: 'orderhome', component: orderhome, name: '居家订单统计', code: 'RHP_ORD', meta: { code: 'RHP_ORD' } },
  ],
}

// 业务设置
const servicetypes = () => import(/* webpackChunkName: 'servicetypes' */ '@/views/businessset/servicetypes') // 服务类型
const pricerule = () => import(/* webpackChunkName: 'pricerule' */ '@/views/businessset/priceRule') // 价格规则
const newPricerule = () => import(/* webpackChunkName: 'newPricerule' */ '@/views/businessset/newPricerule') // 价格规则详情
const invoirule = () => import(/* webpackChunkName: 'invoirule' */ '@/views/businessset/invoirule') // 发票规则
const attendanceinvoirule = () => import(/* webpackChunkName: 'attendanceinvoirule' */ '@/views/businessset/attendanceinvoirule') // 考勤规则
const evaluateset = () => import(/* webpackChunkName: 'evaluateset' */ '@/views/businessset/evaluateset') // 评价设置
const agreementset = () => import(/* webpackChunkName: 'agreementset' */ '@/views/businessset/agreementset') // 协议管理
const newAgreementset = () => import(/* webpackChunkName: 'newAgreementset' */ '@/views/businessset/newAgreementset') // 协议详情
const otherset = () => import(/* webpackChunkName: 'otherset' */ '@/views/businessset/otherset') // 其它设置
const supportservices = () => import(/* webpackChunkName: 'supportservices' */ '@/views/businessset/supportServices.vue') // 配套服务
const setinsurance = () => import(/* webpackChunkName: 'setinsurance' */ '@/views/businessset/setInsurance.vue') // 保险设置
const RoutBusinessset = {
  path: '/businessset',
  component: Layout,
  name: '业务设置',
  // icon
  code: 'BUS',
  children: [
    { path: 'servicetypes', component: servicetypes, name: '服务类型', code: 'BUS_SER', meta: { code: 'BUS_SER' } },
    { path: 'pricerule', component: pricerule, name: '价格规则', code: 'BUS_PRI', meta: { code: 'BUS_PRI' } },
    { path: 'newPricerule', component: newPricerule, name: '价格规则详情', hidden: true },
    { path: 'invoirule', component: invoirule, name: '发票规则', code: 'BUS_INV', meta: { code: 'BUS_INV' } },
    { path: 'attendanceinvoirule', component: attendanceinvoirule, name: '考勤规则', code: 'BUS_ATT', meta: { code: 'BUS_ATT' } },
    { path: 'evaluateset', component: evaluateset, name: '评价设置', code: 'BUS_EVA', meta: { code: 'BUS_EVA' } },
    { path: 'agreementset', component: agreementset, name: '协议管理', code: 'BUS_AGR', meta: { code: 'BUS_AGR' } },
    { path: 'newAgreementset', component: newAgreementset, name: '协议详情', hidden: true },
    { path: 'otherset', component: otherset, name: '其它设置', code: 'BUS_OTH', meta: { code: 'BUS_OTH' } },
    { path: 'supportservices', component: supportservices, name: '配套服务', code: 'BUS_SUS', meta: { code: 'BUS_SUS' } },
    { path: 'setinsurance', component: setinsurance, name: '保险设置', code: 'BUS_INS', meta: { code: 'BUS_INS' } },
  ]
}

// 资源管理
const projectManage = () => import(/* webpackChunkName: 'projectManage' */ '@/views/resourceManage/projectManage') // 项目管理
const newProject = () => import(/* webpackChunkName: 'newProject' */ '@/views/resourceManage/newProject') // 项目详情
const resconnection = () => import(/* webpackChunkName: 'resconnection' */ '@/views/resourceManage/resconnection') // 资源关联
const areamanage = () => import(/* webpackChunkName: 'areaManage' */ '@/views/resourceManage/areaManage') // 区域管理
const department = () => import(/* webpackChunkName: 'department' */ '@/views/resourceManage/department') // 科室管理
const wardmanage = () => import(/* webpackChunkName: 'wardmanage' */ '@/views/resourceManage/wardmanage') // 病区管理
const bedmanage = () => import(/* webpackChunkName: 'bedmanage' */ '@/views/resourceManage/bedmanage') // 床位管理
const newBedmanage = () => import(/* webpackChunkName: 'newBedmanage' */ '@/views/resourceManage/newBedmanage') // 床位详情
const hospitalduties = () => import(/* webpackChunkName: 'hospitalduties' */ '@/views/resourceManage/hospitalduties') // 医院职务
const laborservices = () => import(/* webpackChunkName: 'laborservices' */ '@/views/resourceManage/laborservices') // 劳务公司
const newLaborservices = () => import(/* webpackChunkName: 'newLaborservices' */ '@/views/resourceManage/newLaborservices') // 公司详情
const RouterResource = {
  path: '/resource',
  component: Layout,
  name: '资源管理',
  icon: 'icon-ziyuanguanli',
  code: 'RES',
  children: [
    { path: 'projectManage', component: projectManage, name: '项目管理', code: 'RES_PJT', meta: { code: 'RES_PJT' } },
    { path: 'newProject', component: newProject, name: '项目详情', hidden: true },
    { path: 'resconnection', component: resconnection, name: '资源关联', code: 'RES_CON', meta: { code: 'RES_CON' } },
    { path: 'areamanage', component: areamanage, name: '区域管理', code: 'RES_ARE', meta: { code: 'RES_ARE' } },
    { path: 'department', component: department, name: '科室管理', code: 'RES_DEP', meta: { code: 'RES_DEP' } },
    { path: 'wardmanage', component: wardmanage, name: '病区管理', code: 'RES_WAR', meta: { code: 'RES_WAR' } },
    { path: 'bedmanage', component: bedmanage, name: '床位管理', code: 'RES_BED', meta: { code: 'RES_BED' } },
    { path: 'newBedmanage', component: newBedmanage, name: '床位详情', hidden: true },
    { path: 'hospitalduties', component: hospitalduties, name: '医院职务', code: 'RES_HOS', meta: { code: 'RES_HOS' } },
    { path: 'laborservices', component: laborservices, name: '劳务公司', code: 'RES_LAB', meta: { code: 'RES_LAB' } },
    { path: 'newLaborservices', component: newLaborservices, name: '公司详情', hidden: true },
  ]
}

// 系统设置
const companyInfo = () => import(/* webpackChunkName: 'companyInfo' */ '@/views/systemSetting/companyInfo') // 公司信息
const organizationalStructure = () => import(/* webpackChunkName: 'organizationalStructure' */ '@/views/systemSetting/organizationalStructure')  // 组织架构
const roleAuthority = () => import(/* webpackChunkName: 'roleAuthority' */ '@/views/systemSetting/roleAuthority') // 角色权限
const jobTitle = () => import(/* webpackChunkName: 'jobTitle' */ '@/views/systemSetting/jobTitle') // 职务名称
const systemTopic = () => import(/* webpackChunkName: 'systemTopic' */ '@/views/systemSetting/systemTopic') // 系统主题
const logManage = () => import(/* webpackChunkName: 'logManage' */ '@/views/systemSetting/logManage') // 日志管理 

const RouterSystem = {
  path: '/system',
  component: Layout,
  name: '系统管理',
  icon: 'icon-xitongguanli',
  code: 'SYS', // 权限编码，后台拿的
  children: [
    { path: 'companyInfo', component: companyInfo, name: '公司信息', code: 'SYS_CMP', meta: { code: 'SYS_CMP' } },
    { path: 'organizationalStructure', component: organizationalStructure, name: '组织架构', code: 'SYS_OGN', meta: { code: 'SYS_OGN' } },
    { path: 'roleAuthority', component: roleAuthority, name: '角色权限', code: 'SYS_ROL', meta: { code: 'SYS_ROL' } },
    { path: 'jobTitle', component: jobTitle, name: '职务名称', code: 'SYS_JOB', meta: { code: 'SYS_JOB' } },
    { path: 'systemTopic', component: systemTopic, name: '系统主题', code: 'SYS_TEM', meta: { code: 'SYS_TEM' } },
    { path: 'logManage', component: logManage, name: '日志管理', code: 'SYS_LOG', meta: { code: 'SYS_LOG' } },
  ]
}

export const asyncRouterMap = [RoutUserManage, RoutOrderManage, RoutTeamManage, RoutOperationManage, RoutQuelityManage, RoutFinancialManage, RoutReportStatistics, RoutAccomReport, RoutErrandReport, RoutReporthome, RoutBusinessset, RouterResource, RouterSystem] // 用来存放菜单栏里面的路由
let router = new VueRouter({
  // mode: 'hash',
  mode: 'history', // 不要#地址
  scrollBehavior: () => ((document.querySelector('.main_wrapper') || document.querySelector('body')).scrollTop = 0), // 每次跳转路由都会滚动到最上面
  routes: constantRouterMap.concat(asyncRouterMap) // 将基础路由和菜单路由合并
})


// 每次跳转成功后，都会更新store
router.afterEach((to, from) => {
  // console.log("totototot",to)
  store.commit('setLoading', false)
  if (window._axiosPromiseArr === undefined) {
    window._axiosPromiseArr = []
  }
  // console.log('取消请求的拦截', window._axiosPromiseArr)
  // 循环缓存的请求 取消标识数组 取消所有关联的请求
  window._axiosPromiseArr.forEach((ele, index) => {
    // 失败函数中返回自定义错误信息
    ele.cancel()
    console.log(ele)
    delete window._axiosPromiseArr[index]
  })
  // if (to.meta.title) {
  //   document.title = to.meta.title
  // } else {
  //   document.title = '致程运营平台'
  // }
  if (to.name && to.name !== 'login') {
    store.dispatch('addVisitedViews', to)
  }
})
router.onError((error) => {
  const pattern = /Loading chunk (\d)+ failed/g
  const isChunkLoadFailed = error.message.match(pattern)
  const targetPath = router.history.pending.fullPath
  if (isChunkLoadFailed) {
    router.replace(targetPath)
  }
})

export default router
